.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #89cff0;
}

.name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.name li {
    margin: 2rem 0;
    font-size: 4em;
    font-weight: bold;
    color: white;
}

.description {
    margin: 0 0 0 2rem;
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }

    .name {
        align-items: center;
        font-size: 80%;
    }

    .name li {
        margin: 0;
        text-align: center;
    }

    .description {
        margin: 1rem 0 0 0;
    }

    .description p {
        text-align: center;
        margin: .5rem;
    }
}
